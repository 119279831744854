import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricsDriverSushi from '@amzn/katal-metrics-driver-sushi';
import KatalMetricsDriverConsoleLogJson from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson';
import KatalMetricsDriverArrayCollector from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverArrayCollector';
import { DEFAULT_DOMAIN, DEFAULT_REALM, DOMAIN, REALM } from 'src/constants';

const metricsConsoleErrorHandler = (err: Error) => console.error(err);

const makeMetricsDriver = (): KatalMetrics.MetricsDriver => {
  if (process.env.NODE_ENV === 'test') {
    const metricsDriver = new KatalMetricsDriverArrayCollector();
    //  Attach to global window object so tests can see it
    (window as any).metricsDriver = metricsDriver;
    return metricsDriver;
  }
  if (process.env.NODE_ENV !== 'production') {
    return new KatalMetricsDriverConsoleLogJson();
  }

  return new KatalMetricsDriverSushi.Builder()
    .withDomainRealm(getDomain(), getRealm())
    .withErrorHandler(metricsConsoleErrorHandler)
    .build();
};

const makePublisher = (): KatalMetrics.Publisher => {
  const metricsDriver = makeMetricsDriver();
  const initialMetricsContext = new KatalMetrics.Context.Builder()
    .withSite('SellerCentral')
    .withServiceName('IPIWebAssets')
    .build();
  return new KatalMetrics.Publisher(
    metricsDriver,
    metricsConsoleErrorHandler,
    initialMetricsContext,
  );
};

export const initialMetricsPublisher = makePublisher();

function getDomain() {
  return DOMAIN['beta'] || DEFAULT_DOMAIN;
}

function getRealm() {
  return REALM['us-west-2'] || DEFAULT_REALM;
}
